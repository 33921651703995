import axios from 'axios'

const energyRequest = axios.create({
  baseURL: '/api/powermanage'
})

const Api = {
  // auth
  GetLastDoc: '/poweroverview/lastdoc',
  GetAccPowerToday: '/poweroverview/accpowertoday',
  GetAccPowerThisMon: '/poweroverview/accpowerthismon',
  GetReRatioThisMon: '/poweroverview/reratiothismon'
}

// -------------------------------------------------------------
// ------------------------- 用電 -------------------------------
// -------------------------------------------------------------
export const getLastDocApi = (fieldName) =>
  energyRequest.post(Api.GetLastDoc, { fieldName })

export const getAccPowerTodayApi = (fieldName) =>
  energyRequest.post(Api.GetAccPowerToday, { fieldName })

export const getAccPowerThisMonApi = (fieldName) =>
  energyRequest.post(Api.GetAccPowerThisMon, { fieldName })

export const getReRatioThisMonApi = (fieldName) =>
  energyRequest.post(Api.GetReRatioThisMon, { fieldName })
