export const projectSolarSiteinfoChildrenRoutes = [
  {
    path: 'siteinfodetail',
    name: 'siteinfodetail',
    component: () => import('@/components/SiteInfoDetail/SiteInfoDetail.vue'),
    meta: {
      release: true,
      zhTw: '案場資訊'
    }
  },
  {
    path: 'devicestatus',
    name: 'devicestatus',
    component: () => import('@/components/DeviceStatus.vue'),
    meta: {
      release: true,
      zhTw: '裝置即時資訊'
    }
  },
  {
    path: 'devicehistory',
    name: 'devicehistory',
    component: () => import('@/components/DeviceHistory.vue'),
    meta: {
      release: true,
      zhTw: '裝置歷史資料'
    }
  },
  {
    path: 'dataanalysis',
    name: 'dataanalysis',
    component: () => import('@/components/DataAnalysis.vue'),
    meta: {
      release: true,
      zhTw: '資料分析'
    }
  },
  {
    path: 'mpptanalysis',
    name: 'mpptanalysis',
    component: () => import('@/components/MpptAnalysis.vue'),
    meta: {
      release: true,
      zhTw: 'MPPT分析'
    }
  },
  {
    path: 'dataexcel',
    name: 'dataexcel',
    component: () => import('@/components/DataExcel.vue'),
    meta: {
      release: true,
      zhTw: '設計資訊'
    }
  },
  {
    path: 'investmentanalysis',
    name: 'investmentanalysis',
    component: () => import('@/components/InvestmentAnalysis.vue'),
    meta: {
      release: true,
      zhTw: '投資分析'
    }
  },
  {
    path: 'monthlyreport',
    name: 'monthlyreport',
    component: () => import('@/components/MonthlyReport.vue'),
    meta: {
      release: true,
      zhTw: '案場報表'
    }
  },
  {
    path: 'otherinformation',
    name: 'otherinformation',
    component: () => import('@/components/OtherInformation.vue'),
    meta: {
      release: true,
      zhTw: '其他資訊'
    }
  }

  // {
  //   path: 'deviceinfo',
  //   name: 'deviceinfo',
  //   component: () => import('@/components/DeviceInfo.vue'),
  //   meta: { release: false }
  // },

  // {
  //   path: 'pvstringfailureanalysis',
  //   name: 'pvstringfailureanalysis',
  //   component: () => import('@/components/PvStringFailureAnalysis.vue'),
  //   meta: {
  //     release: false
  //   },
  //   children: [
  //     {
  //       path: 'powerfee',
  //       name: 'powerfee',
  //       component: () =>
  //         import('@/components/PvStringComponents/PowerFee.vue')
  //     },
  //     {
  //       path: 'groupconfig',
  //       name: 'groupconfig',
  //       component: () =>
  //         import('@/components/PvStringComponents/GroupConfig.vue')
  //     },
  //     {
  //       path: 'circlechart',
  //       name: 'circlechart',
  //       component: () =>
  //         import('@/components/PvStringComponents/CircleChart.vue')
  //     },
  //     {
  //       path: 'queryhistorydata',
  //       name: 'queryhistorydata',
  //       component: () =>
  //         import('@/components/PvStringComponents/QueryHistoryData.vue')
  //     }
  //   ]
  // }
]

export const projectSolarRoutes = [
  {
    path: 'realtime',
    name: 'realtime',
    component: () => import('@/views/Realtime.vue'),
    meta: {
      release: true, // 出現於 群組管理選項 (即時資訊不用設定勾選 任何權限皆有)
      zhTw: '即時資訊',
      keepAlive: true,
      icon: 'fas fa-home',
      showMenu: true // 出現於 左側選單 (展示電視牆 在群組管理選項 不在 sideMenu)
    }
  },
  {
    path: 'foreignfactories',
    name: 'foreignfactories',
    component: () => import('@/views/ForeignFactories.vue'),
    meta: {
      release: true,
      zhTw: '外來監控資訊',
      icon: 'fas fa-poll',
      showMenu: true
    }
  },
  {
    path: 'factorystatus',
    name: 'factorystatus',
    component: () => import('@/views/FactoryStatus.vue'),
    meta: {
      release: true,
      zhTw: '案場狀態',
      icon: 'fas fa-exclamation-circle',
      showMenu: true
    }
  },
  {
    path: 'historyalarms',
    name: 'historyalarms',
    component: () => import('@/views/HistoryAlarms.vue'),
    meta: {
      release: true,
      zhTw: '告警事件',
      icon: 'fa fa-bell',
      showMenu: true
    }
  },
  {
    path: 'usermanage',
    name: 'usermanage',
    component: () => import('@/views/UserManage.vue'),
    meta: {
      release: true,
      zhTw: '使用者管理',
      icon: 'fa fa-user',
      showMenu: true
    }
  },
  {
    path: 'groupmanage',
    name: 'groupmanage',
    component: () => import('@/views/GroupManage.vue'),
    meta: {
      release: true,
      zhTw: '群組管理',
      icon: 'fa fa-users',
      showMenu: true
    }
  },
  {
    path: 'emailsend',
    name: 'emailsend',
    component: () => import('@/views/EmailSend.vue'),
    meta: {
      release: true,
      zhTw: '信件補發',
      icon: 'fas fa-envelope',
      showMenu: true
    }
  },
  {
    path: 'multiplefactoryanalysis',
    name: 'multiplefactoryanalysis',
    component: () => import('@/views/MultipleFactoryAnalysis.vue'),
    meta: {
      release: true,
      zhTw: '跨案場分析',
      icon: 'fas fa-chart-line',
      showMenu: true
    }
  },
  {
    path: 'rawdatadownload',
    name: 'rawdatadownload',
    component: () => import('@/views/RawDataDownload.vue'),
    meta: {
      release: true,
      zhTw: '原始資料下載',
      icon: 'fas fa-solid fa-download',
      showMenu: true
    }
  },
  {
    path: 'raanalysis',
    name: 'raanalysis',
    component: () => import('@/views/RaAnalysis.vue'),
    meta: {
      release: true,
      zhTw: 'RA 資料分析',
      icon: 'fas fa-solid fa-table',
      showMenu: true
    }
  },
  {
    path: 'kpireport',
    name: 'kpireport',
    component: () => import('@/views/KpiReport.vue'),
    meta: {
      release: true,
      zhTw: 'KPI 每月報告',
      icon: 'far fa-file-alt',
      showMenu: true
    }
  },
  {
    path: 'sortsetting',
    name: 'sortsetting',
    component: () => import('@/views/SortSetting.vue'),
    meta: {
      release: true,
      zhTw: '案場排序',
      icon: 'fas fa-sort-amount-up',
      showMenu: true
    }
  },
  {
    path: 'fuqiangxindemo',
    name: 'fuqiangxindemo',
    component: () => import('@/views/FuQiangXinDemo.vue'),
    meta: {
      release: true,
      zhTw: '富強鑫展示畫面',
      icon: 'fas fa-tachometer-alt',
      showMenu: true
    }
  },
  {
    path: 'tvwalllooping',
    name: 'tvwalllooping',
    component: () => import('@/views/TvWallLooping.vue'),
    meta: {
      release: true,
      zhTw: '電視牆輪播',
      icon: 'fas fa-desktop',
      showMenu: true
    }
  },
  // {
  //   path: 'assistant',
  //   name: 'assistant',
  //   component: () => import('@/views/Assistant.vue'),
  //   meta: {
  //     release: true,
  //     zhTw: '小幫手',
  //     icon: 'fas fa-comment-dots'
  //   }
  // },
  {
    path: 'tvscreen',
    name: 'tvscreen',
    component: () => import('@/views/TvScreen.vue'),
    meta: {
      // authorize: [Role.Whole_Admin, Role.Admin],
      release: true,
      zhTw: '案場資訊 - 展示畫面(電視牆)',
      showMenu: false
    }
  },
  // {
  //   path: 'sitecomparation',
  //   name: 'sitecomparation',
  //   component: () => import('@/views/SiteComparation.vue'),
  //   meta: { release: false }
  // },
  // {
  //   path: 'fangde',
  //   name: 'fangde',
  //   component: () => import('@/views/FangDe.vue'),
  //   meta: {
  //     release: false
  //   }
  // },
  // {
  //   path: 'fuqiangxin',
  //   name: 'fuqiangxin',
  //   component: () => import('@/views/FuQiangXin.vue'),
  //   meta: {
  //     release: false
  //   }
  // },
  // {
  //   path: 'linechart',
  //   name: 'linechart',
  //   component: () => import('@/views/LineChart.vue'),
  //   meta: {
  //     release: false
  //   }
  // },
  // {
  //   path: 'cookieerror',
  //   name: 'cookieerror',
  //   component: () => import('@/views/CookieError.vue'),
  //   meta: { release: false }
  // },
  {
    path: 'siteinfo',
    name: 'siteinfo',
    component: () => import('@/views/SiteInfo.vue'),
    children: projectSolarSiteinfoChildrenRoutes
  }
]
