// You still need to register Vuetify itself
// src/plugins/vuetify.js

import '@mdi/font/css/materialdesignicons.css' // Ensure you are using css-loader
import Vue from 'vue'
import Vuetify from 'vuetify/lib'
// import 'vuetify/dist/vuetify.min.css' // 不用引入, 不然會有兩層一樣的 css
// import colors from 'vuetify/lib/util/colors'

Vue.use(Vuetify)

const opts = {
  icons: {
    iconfont: 'mdi' // default - only for display purposes
  },
  // options: {
  //   customProperties: false // 禁用 CSS Reset
  // }

  theme: {
    themes: {
      light: {
        // primary: colors.lime.base, // default #1976d2
        // secondary: '#b0bec5',
        // accent: '#8c9eff'
        acme_blue: '#4885c1',
        error: '#aa0000'
      }
    }
  }
}

export default new Vuetify(opts)
