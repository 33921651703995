import Vue from 'vue'
import VueRouter from 'vue-router'
import i18n from '@/lang/lang'
import { setupRouterGuard } from './guard'
// import { auth, updatedToken } from '../http/msalMethods'

import NotFound from '../views/NotFound.vue'

import {
  projectSolarRoutes,
  projectEnergyRoutes,
  projectBatteryRoutes
} from './routes'

Vue.use(VueRouter)

// 解决编程式路由往同一地址跳转时会报错的情况
// 無 cookies auth_token 時，導回登入頁會報錯
const originalPush = VueRouter.prototype.push
const originalReplace = VueRouter.prototype.replace
// push
VueRouter.prototype.push = function push (location, onResolve, onReject) {
  if (onResolve || onReject) {
    return originalPush.call(this, location, onResolve, onReject)
  }
  return originalPush.call(this, location).catch((err) => err)
}
// replace
VueRouter.prototype.replace = function push (location, onResolve, onReject) {
  if (onResolve || onReject) {
    return originalReplace.call(this, location, onResolve, onReject)
  }
  return originalReplace.call(this, location).catch((err) => err)
}

const supportedLanguages = ['en', 'tw']

const routes = [
  {
    path: '/login',
    name: 'login',
    component: () => import('../views/Login.vue')
  },
  {
    path: '/',
    name: 'root'
    // redirect: `${i18n.locale}/realtime` // 在 router beforeEach 處理
  },
  {
    // path: '/:lang(tw|en)', // 不用 beforeEnter 的話 用此正則表達式
    path: '/:lang',
    beforeEnter: (to, from, next) => {
      if (supportedLanguages.includes(to.params.lang)) {
        next()
      } else {
        next({ name: 'Not Found' })
      }
    },
    component: {
      name: 'langRouterView', // 匿名組件的名稱
      render (c) {
        return c('router-view') // 動態渲染 <router-view>
      }
    },
    children: [
      ...projectSolarRoutes,
      {
        path: 'energy',
        name: 'energy',
        component: () => import('@/views/projectEnergy/index.vue'),
        redirect: { name: 'EnergyOverview', params: { lang: i18n.locale } },
        children: [...projectEnergyRoutes]
      },
      {
        path: 'battery',
        name: 'battery',
        component: () => import('@/views/projectBattery/index.vue'),
        redirect: { name: 'BatteryOverview', params: { lang: i18n.locale } },
        children: [...projectBatteryRoutes]
      },
      // {
      //   path: '', // 匹配結尾為 `/tw/` 或 `/en/`
      //   redirect: { name: 'Not Found' }
      // },
      {
        path: ':pathMatch(.*)*', // 捕捉未匹配的子路徑 不可/:pathMatch(.*)* (children 與 全域混亂)
        name: 'Lang Not Found',
        component: NotFound
      }
    ]
  },
  {
    path: '/:pathMatch(.*)*', // 全域捕捉未匹配路徑
    name: 'Not Found',
    component: NotFound
  }
]

const router = new VueRouter({
  mode: 'history',
  routes
})

setupRouterGuard(router)

export { routes }
export default router
