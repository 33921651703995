import {
  getLastDocApi,
  getAccPowerTodayApi,
  getAccPowerThisMonApi,
  getReRatioThisMonApi
} from '@/api/energy'
import { DateTime } from 'luxon'

const state = {
  selectedFieldName: '禾豐光用電展示案場',
  energyMinData: [], // 每分鐘 meters 內容
  accPowerTodayData: [], // 各 meter 今日累計用電
  accPowerThisMonData: [], // 各 meter 本月累計用電
  reRationThisMon: 0,
  lastUpdateTime: ''
}

const getters = {
  // 即時用電功率
  getInstantDemandSum: (state) => {
    if (state.energyMinData?.length) {
      const sum = state.energyMinData.reduce(
        (a, b) => a.instant_demand + b.instant_demand
      )
      return Math.round(sum * 100) / 100
    }
    return '-'
  },
  // 用電 - 本月累計用電量
  getAccPowerThisMonSum: (state) => {
    if (state.accPowerThisMonData?.length) {
      const sum = state.accPowerThisMonData.reduce(
        (a, b) => a.powerGenThisMon + b.powerGenThisMon
      )
      return Math.round(sum * 100) / 100
    }

    return '-'
  },
  // 用電 - 本月用電占比
  getAccPowerThisMonPercentageData: (state, getters) => {
    if (state.accPowerThisMonData?.length) {
      return state.accPowerThisMonData.map((obj) => ({
        name: obj.meterId,
        value: obj.powerGenThisMon, // tooltip 顯示實際數值用 (預設是 %)
        y:
          Math.round(
            (obj.powerGenThisMon / getters.getAccPowerThisMonSum) * 1000
          ) / 10
      }))
    }
  }
}

const mutations = {
  SET_LAST_UPDATE_TIME (state, payload) {
    state.lastUpdateTime = DateTime.fromISO(payload.timestamp).toFormat(
      'yyyy-MM-dd HH:mm:ss'
    )
  },
  // 各 meter 資訊
  SET_ENERGY_MIN_DATA (state, payload) {
    state.energyMinData = payload.meters
  },
  // 各 meter 今日用電
  SET_ACC_POWER_TODAY_DATA (state, payload) {
    state.accPowerTodayData = payload.map((obj) => ({
      ...obj,
      powerGenToday: Math.round(obj.powerGenToday * 100) / 100
    }))
  },
  // 本月累計用電, 本月用電占比
  SET_ACC_POWER_THIS_MON_DATA (state, payload) {
    state.accPowerThisMonData = payload.map((obj) => ({
      ...obj,
      powerGenThisMon: Math.round(obj.powerGenThisMon * 100) / 100
    }))
  },
  SET_RE_RATION_THIS_MON (state, payload) {
    state.reRationThisMon = payload
  }
}

const actions = {
  // 接收 websocket 傳的 各 meter 資訊
  setEnergyMinData ({ commit }, data) {
    commit('SET_ENERGY_MIN_DATA', data)
    commit('SET_LAST_UPDATE_TIME', data)
  },
  // api 各 meter 最新一筆資訊
  async getLastDocData ({ commit }) {
    try {
      const res = await getLastDocApi('禾豐光用電展示案場')
      commit('SET_ENERGY_MIN_DATA', res.data.data?.[0])
      commit('SET_LAST_UPDATE_TIME', res.data.data?.[0])
    } catch (error) {
      console.log('getLastDocData', error)
    }
  },
  async getAccPowerTodayData ({ state, commit }) {
    try {
      const res = await getAccPowerTodayApi(state.selectedFieldName)
      commit('SET_ACC_POWER_TODAY_DATA', res.data.data)
    } catch (error) {
      console.log('getAccPowerTodayData', error)
    }
  },
  async getAccPowerThisMonData ({ state, commit }) {
    try {
      const res = await getAccPowerThisMonApi(state.selectedFieldName)
      commit('SET_ACC_POWER_THIS_MON_DATA', res.data.data)
    } catch (error) {
      console.log('getAccPowerThisMonData', error)
    }
  },
  async getReRatioThisMon ({ state, commit }) {
    try {
      const res = await getReRatioThisMonApi(state.selectedFieldName)
      commit('SET_RE_RATION_THIS_MON', res.data.data)
    } catch (error) {
      console.log('getReRatioThisMon', error)
    }
  }
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
